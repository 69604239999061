import React, { useEffect, useState } from 'react'
import { EmailButtonContainer, NotificationContainer } from './styles'

import { useTranslation } from 'react-i18next'

const EmailButton = ({ id, iconOutline }) => {
  const [labelsContactEmail, setLabelsContactEmail] = useState('Email')
  const [messagesCopySuccess, setMessagesCopySuccess] = useState('')
  const [messagesCopyError, setMessagesCopyError] = useState('')
  const [copySuccessStatus, setCopySuccessStatus] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    setLabelsContactEmail(t('labels.contactEmail'))
    setMessagesCopySuccess(t('messages.copySuccess'))
    setMessagesCopyError(t('messages.copyError'))
  }, [t])

  useEffect(() => {
    if (copySuccessStatus === null) return
    const el = document.getElementById(`note_copyStatus_${id}`)
    setTimeout(() => {
      fireNotification(el)
    }, 40)
  }, [copySuccessStatus])

  const copyToClipboard = () => {
    if (copySuccessStatus !== null) return

    navigator.clipboard
      .writeText(labelsContactEmail)
      .then(() => {
        setCopySuccessStatus(true)
      })
      .catch(() => {
        setCopySuccessStatus(false)
      })
  }

  const fireNotification = element => {
    element.classList.add('active')
    setTimeout(() => {
      element.classList.remove('active')
      setTimeout(() => {
        setCopySuccessStatus(null)
      }, 350)
    }, 3000)
  }

  return (
    <EmailButtonContainer
      className="flexbox v-centered"
      id="btn_email"
      type="button"
      onClick={copyToClipboard}
    >
      <span className="material-icons-round">
        {iconOutline ? 'mail_outline' : 'email'}
      </span>
      <span className="contact">{labelsContactEmail}</span>

      <NotificationContainer
        id={`note_copyStatus_${id}`}
        success={copySuccessStatus}
      >
        <div className="arrow"></div>
        <div className="flexbox v-centered">
          <span className="material-icons-round">
            {copySuccessStatus ? 'done' : 'error'}
          </span>
          <span>
            {copySuccessStatus ? messagesCopySuccess : messagesCopyError}
          </span>
        </div>
      </NotificationContainer>
    </EmailButtonContainer>
  )
}

export default EmailButton
