import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@/contexts/AuthContext'

import { PAGE_PATHS, SNOWPLOW_SCHEMAS } from '@/services/paths'

import { trackSelfDescribingEvent, trackPageView } from '@snowplow/browser-tracker'

const Public = ({ children }) => {
  const router = useRouter()
  const { loadingUser, signed, embedded } = useAuth()

  useEffect(() => {
    trackPageView({ title: router.asPath })
  }, [])

  useEffect(() => {
    if (loadingUser) return

    if (signed) {
      if (embedded === true) {
        router.push(PAGE_PATHS.embedded)
      } else{
        router.push(PAGE_PATHS.home)
      }
    } else {
      // Comportamentos padrão para páginas públicas
    }
  }, [loadingUser, signed])

  return !loadingUser && !signed && children
}

export default Public
