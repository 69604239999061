import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { ASSET_PATHS, PAGE_PATHS } from '@/services/paths'

import { useTranslation } from 'react-i18next'
import Image from 'next/image'

const Logo = ({ isSigned }) => {
  const [altLogo, setAltLogo] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    setAltLogo(t('alts.logo'))
  }, [t])

  return (
    <S.LogoContainer isSigned={isSigned}>
      <a href={isSigned ? PAGE_PATHS.home : PAGE_PATHS.landing} id="btn_home">
        <Image src={ASSET_PATHS.logo} alt={altLogo} width={300} height={107} />
      </a>
    </S.LogoContainer>
  )
}

export default Logo
