import styled from 'styled-components'

export const HeaderUnsignedWrapper = styled.header`
  background: var(--light4);
  padding: 2rem 0;
  position: relative;
  z-index: 2;

  div#header_selector {
    position: absolute;
    height: 4px;
    border-radius: 50px;
    background: var(--blue2);
    width: 0px;
    left: 0;

    @media only screen and (max-width: 1050px) {
      display: none;
    }
  }
`

export const HeaderUnsignedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

export const HeaderNavContainer = styled.nav`
  display: flex;
  align-items: center;
`

export const HeaderButtonContainer = styled.div`
  margin-right: 1.5rem;

  a {
    background: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--grey2);
    transition: 0.5s all;

    &:hover,
    &.active {
      color: var(--blue);
    }

    &.active {
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-right: 2.5rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    display: none;
  }
`

export const HeaderLinkContainer = styled.div`
  display: flex;

  a:not(:last-of-type) {
    margin-right: 1rem;
  }

  button {
    display: none;
    background: none;

    span {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 1050px) {
    a:not(:last-of-type) {
      margin-right: 2rem;
    }

    a:last-of-type {
      display: none;
    }

    button {
      display: block;
    }
  }
`

export const HeaderFloatingMenuContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;
  background: var(--light);
  padding: 2rem;
  transition: all 300ms;

  &.active {
    left: 0;
  }

  &:not(.active) {
    left: 100%;
  }

  button {
    background: none;
  }
`

export const FloatingMenuNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6rem;

  span {
    font-size: 36px;
    color: var(--grey2);
  }
`

export const FloatingMenuButtonContainer = styled.div`
  margin-bottom: 4rem;

  button,
  a {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    display: block;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`

export const FloatingMenuLinkContainer = styled.div`
  & > * {
    width: 100%;
    max-width: 100% !important;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`
