import styled from 'styled-components'

export const LogoContainer = styled.div`
  width: ${props => (props.isSigned ? '20%' : '9%')};

  img {
    width: 100%;
    max-width: 180px;
    height: auto;
  }

  @media only screen and (max-width: 724px) {
    width: 40%;
  }
`
