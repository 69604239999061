import styled from 'styled-components'

export const EmailButtonContainer = styled.button`
  background: none;
  color: inherit;
  position: relative;

  span.contact:hover {
    text-decoration: underline;
  }
`
export const NotificationContainer = styled.div`
  background: ${props => (props.success ? 'var(--success)' : 'var(--error)')};
  color: var(--light);
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 10%;
  top: 50%;
  opacity: 0;
  transition: all 300ms;
  pointer-events: none;

  &.active {
    top: 150%;
    opacity: 1;
  }

  .arrow {
    border: 7.5px solid;
    width: 15px;
    height: 15px;
    border-color: transparent transparent
      ${props => (props.success ? 'var(--success)' : 'var(--error)')};
    position: absolute;
    left: 15%;
    top: 0;
    transform: translateY(-100%);
  }

  .material-icons,
  .material-icons-round {
    color: var(--light) !important;
  }
`
