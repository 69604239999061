import styled from 'styled-components'

export const FooterContainer = styled.div`
  margin: 0 auto;
  padding: 5rem 0 4rem;

  button {
    background: none;
  }

  button,
  a {
    &.social-link {
      filter: invert(34%) sepia(2%) saturate(2878%) hue-rotate(170deg)
        brightness(87%) contrast(95%);

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .menu-flags {
    padding: 0;
    font-size: 16px;

    span {
      padding: 0;
      font-weight: 500;
    }

    svg {
      display: none;
    }

    li {
      color: var(--grey2);

      & span {
        font-weight: 400;
      }
    }
  }

  .menu-flags-button {
    display: flex;
    border: 1px solid var(--grey2);
    color: var(--grey2);
    width: auto;
    margin-bottom: 0 !important;

    &::after {
      margin-left: 2rem;
    }

    &[aria-expanded='true']::after {
      margin-left: 2rem;
      border-bottom: 5px solid var(--grey2);
    }
  }
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey3);
  margin-bottom: 1.5rem;

  button,
  a {
    font-size: 16px;
    font-weight: 700;
    color: var(--grey2);

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }

  div:not(#button-menu) {
    margin-bottom: 1rem;
    margin-right: 10%;
  }

  div#button-menu {
    position: absolute;
  }

  @media only screen and (max-width: 1050px) {
    justify-content: space-between;
    margin-bottom: 2rem;

    div:not(#button-menu) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    div#button-menu {
      position: relative;
      left: 0 !important;
      margin-bottom: 0.5rem;
    }

    button,
    a {
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    div#button-menu {
      display: none;
    }
  }
`

export const LinkMenu = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 1.25rem;
    font-weight: 500;
    color: var(--grey18);
  }

  button,
  a {
    display: block;
    font-size: 16px;
    color: var(--grey17);

    &:not(:last-child) {
      &:not(.social-link) {
        margin-bottom: 2rem;
      }
    }

    &.hover:hover > span:not(.material-icons-round) {
      text-decoration: underline;
    }
  }

  .joined-col {
    display: none;

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    .joined-col {
      display: block;
    }

    .desktop-only {
      display: none;
    }
  }

  @media only screen and (max-width: 700px) {
    display: block;
    margin-top: 4rem;

    & > *:not(:last-child),
    .joined-col > *:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`

export const ContactsMenu = styled.div`
  button,
  a {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem !important;

    & > *:first-child {
      margin-right: 0.5rem;
    }
  }

  img {
    width: 24px;
    filter: invert(30%) sepia(14%) saturate(371%) hue-rotate(169deg)
      brightness(94%) contrast(90%);
  }
`

export const MobileSocialLinks = styled.div`
  display: none;
  margin-top: 6rem;

  & > *:first-child {
    margin-right: 3rem;
  }

  @media only screen and (max-width: 1050px) {
    display: flex;
  }
`

export const Copyright = styled.div`
  margin: 0 auto;
  padding: 6rem 0 0;
  text-align: center;

  .mobile-only {
    display: none;

    & > *:first-child {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 700px) {
    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }
  }
`
