import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import Logo from '@/components/Header/Logo'

import { PAGE_PATHS } from '@/services/paths'
import { getLocalizedZendeskLink } from '@/services/language'

import { useTranslation } from 'react-i18next'

const HeaderUnsigned = ({ pageName }) => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [titlesHome, setTitlesHome] = useState('')
  const [titlesContents, setTitlesContents] = useState('')
  const [titlesIntegration, setTitlesIntegration] = useState('')
  const [titlesBlog, setTitlesBlog] = useState('')
  const [titlesContact, setTitlesContact] = useState('')
  const [titlesHelp, setTitlesHelp] = useState('')
  const [titlesLogin, setTitlesLogin] = useState('')

  const [showFloatingMenu, setShowFloatingMenu] = useState(false)

  const didInit = useRef(false)

  useEffect(() => {
    setTitlesHome(t('titles.home'))
    setTitlesContents(t('titles.contents'))
    setTitlesIntegration(t('titles.integration'))
    setTitlesBlog(t('titles.blog'))
    setTitlesContact(t('titles.contact'))
    setTitlesHelp(t('titles.help'))
    setTitlesLogin(t('titles.login'))
  }, [t])

  useEffect(() => {
    if (!windowSize.width) return

    applySelectedStyle()
  }, [windowSize])

  useEffect(() => {
    if (!showFloatingMenu) return

    setTimeout(
      () => document.getElementById('floating-menu').classList.add('active'),
      100
    )
  }, [showFloatingMenu])

  useEffect(() => {
    if (didInit.current) return

    didInit.current = true

    setTimeout(applySelectedStyle, 450)

    const observer = new window.MutationObserver(event => {
      // console.log(event)
      applySelectedStyle()
    })
    const div = document.getElementById('header_btn_container')
    observer.observe(div, {
      childList: true,
      subtree: true,
      characterData: true
    })
  }, [])

  const applySelectedStyle = () => {
    if (!pageName || windowSize.width < 1050) return

    const btn = document.getElementById(`btn_header_${pageName}`)

    if (pageName === 'signin' || pageName === 'contact') {
      btn.classList.add('inactive')
    } else {
      const bar = document.getElementById('header_selector')

      btn.classList.add('active')

      bar.style.width = `${btn.offsetWidth}px`
      bar.style.left = `${btn.offsetLeft}px`
    }
  }

  const toggleFloatingMenu = () => {
    const show = !showFloatingMenu
    if (show) {
      setShowFloatingMenu(true)
    } else {
      document.getElementById('floating-menu').classList.remove('active')
      setTimeout(() => setShowFloatingMenu(false), 350)
    }
  }

  return (
    <S.HeaderUnsignedWrapper>
      <S.HeaderUnsignedContainer className="mw-unsigned">
        <Logo />

        <S.HeaderNavContainer>
          <S.HeaderButtonContainer id="header_btn_container">
            <a href={PAGE_PATHS.landing} id="btn_header_landing">
              {titlesHome}
            </a>
            <a href={PAGE_PATHS.contents} id="btn_header_contents">
              {titlesContents}
            </a>
            <a href={PAGE_PATHS.integration} id="btn_header_integration">
              {titlesIntegration}
            </a>
            <a
              href={PAGE_PATHS.blog}
              id="btn_header_blog"
              target="_blank"
              rel="noopener noreferrer"
            >
              {titlesBlog}
            </a>
            <a
              href={getLocalizedZendeskLink()}
              id="btn_header_help"
              target="_blank"
              rel="noopener noreferrer"
            >
              {titlesHelp}
            </a>
          </S.HeaderButtonContainer>

          <S.HeaderLinkContainer>
            <a
              href={PAGE_PATHS.contact}
              id="btn_header_contact"
              className="btn round md blue shadow"
            >
              {titlesContact}
            </a>
            <a
              href={PAGE_PATHS.signin}
              id="btn_header_signin"
              className="btn round md alternate"
            >
              {titlesLogin}
            </a>
            <button
              type="button"
              id="btn_header_menu"
              onClick={toggleFloatingMenu}
            >
              <span className="material-icons-round">menu</span>
            </button>
          </S.HeaderLinkContainer>
        </S.HeaderNavContainer>
      </S.HeaderUnsignedContainer>
      <div id="header_selector"></div>

      {showFloatingMenu && (
        <S.HeaderFloatingMenuContainer id="floating-menu">
          <S.FloatingMenuNavContainer>
            <Logo />
            <button
              type="button"
              id="btn_menu_close"
              onClick={toggleFloatingMenu}
            >
              <span className="material-icons-round">close</span>
            </button>
          </S.FloatingMenuNavContainer>

          <S.FloatingMenuButtonContainer>
            <a href={PAGE_PATHS.landing} id="btn_menu_home">
              {titlesHome}
            </a>
            <a href={PAGE_PATHS.contents} id="btn_menu_contents">
              {titlesContents}
            </a>
            <a href={PAGE_PATHS.integration} id="btn_menu_integration">
              {titlesIntegration}
            </a>
            <a
              href={PAGE_PATHS.blog}
              id="btn_menu_blog"
              target="_blank"
              rel="noopener noreferrer"
            >
              {titlesBlog}
            </a>
            <a
              href={getLocalizedZendeskLink()}
              id="btn_menu_help"
              target="_blank"
              rel="noopener noreferrer"
            >
              {titlesHelp}
            </a>
          </S.FloatingMenuButtonContainer>

          <S.FloatingMenuLinkContainer>
            <a
              href={PAGE_PATHS.contact}
              id="btn_menu_contact"
              className="btn round lg blue shadow"
            >
              {titlesContact}
            </a>
            <a
              href={PAGE_PATHS.signin}
              id="btn_menu_login"
              className="btn round lg alternate"
            >
              {titlesLogin}
            </a>
          </S.FloatingMenuLinkContainer>
        </S.HeaderFloatingMenuContainer>
      )}
    </S.HeaderUnsignedWrapper>
  )
}

export default HeaderUnsigned
